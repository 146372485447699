import { useMsal } from "@azure/msal-react";
import { AppBarComponent, Inject } from "@syncfusion/ej2-react-navigations";
import {
  DropDownButton,
  DropDownButtonComponent,
} from "@syncfusion/ej2-react-splitbuttons";
import logo from "../assets/Icons/baystream.png";
import "../styles/NavigationBar.scss";

const NavigationBar = () => {
  const { instance } = useMsal();
  let loggedInUser = sessionStorage.getItem("loggedInUser");

  const items: any[] = [
    {
      text: "Logout",
      iconCss: "e-icons e-logout",
    },
  ];

  const handleClick = (e: { item: { text: any } }) => {
    if (e?.item?.text === "Logout") {
      sessionStorage.clear();
      localStorage.clear();
      instance.logoutRedirect();
    }
  };

  return (
    <div className="default-appbar-container">
      <AppBarComponent colorMode="Primary">
        <div className="nav-container">
          <img
            src={logo}
            height={"30px"}
            width={"30px"}
            className="baystream-logo"
            alt="nav-logo"
          />
          <span className="regular">View Maven</span>
        </div>
        {loggedInUser && (
          <>
            <div className="nav-right-buttons">
              <div className="dropdown-btn">
                <DropDownButtonComponent
                  items={items}
                  content={loggedInUser}
                  select={handleClick}
                >
                  <Inject services={[DropDownButton]} />
                </DropDownButtonComponent>
              </div>
            </div>
          </>
        )}
      </AppBarComponent>
    </div>
  );
};

export default NavigationBar;
