import { doFetch, REQUEST_METHODS } from "../fetcher";
import { SPREADSHEET_APIS_ENDPOINTS } from "./SpreadsheetApiEndPoints";

export default {
  saveUserProfile: (data: any) =>
    doFetch(
      `${SPREADSHEET_APIS_ENDPOINTS.USER_PROFILE}`,
      REQUEST_METHODS.POST,
      data
    ),
};
