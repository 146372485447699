import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

const queryParameters = new URLSearchParams(window.location.search)
let docId = queryParameters.get("docId");
if (docId == null) {
    docId = sessionStorage.getItem('docId');
}
else
    sessionStorage.setItem('docId', docId!);

let fileId = queryParameters.get("fileId")
if (fileId == null) {
    fileId = sessionStorage.getItem('fileId');
}
else
    sessionStorage.setItem('fileId', fileId!);

let host = queryParameters.get("host");
if (host == null) {
    host = sessionStorage.getItem('host');
}
else
    sessionStorage.setItem('host', host!);

let vaultId = queryParameters.get("vault");
if (vaultId == null) {
    vaultId = sessionStorage.getItem('vaultId');
}
else
    sessionStorage.setItem('vaultId', vaultId!);

let vaultName = queryParameters.get("vName");
console.log(vaultName);
if (vaultName == null) {
    vaultName = sessionStorage.getItem('vaultName');
}
else
    sessionStorage.setItem('vaultName', vaultName!);

let fileName = queryParameters.get("fileName")
if (fileName == null) {
    fileName = sessionStorage.getItem('fileName');
}
else
    sessionStorage.setItem('fileName', fileName!);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
