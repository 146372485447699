import { classList } from "@syncfusion/ej2-base";
import { ButtonComponent, CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { useNavigate } from 'react-router-dom';
import {
  GridComponent,
  Inject,
  RowDD,
  Selection,
} from "@syncfusion/ej2-react-grids";
import { BeforeOpenCloseMenuEventArgs } from "@syncfusion/ej2-react-navigations";
import {
  DateTimePickerComponent,
  DatePickerComponent,
} from "@syncfusion/ej2-react-calendars";
import { DialogComponent, TooltipComponent } from "@syncfusion/ej2-react-popups";
import {
  CellModel,
  SpreadsheetComponent,
  getCell,
  getCellAddress,
  getColumnHeaderText,
  getRangeAddress,
  getRangeIndexes,
} from "@syncfusion/ej2-react-spreadsheet";
import React, { useEffect, useMemo, useState } from "react";
import useIsMobile from "../hooks/ListenToResize";
import {
  cellStyle,
  ColumnHeaderStyle,
  ColumnStyle,
  firstPosition,
  settings,
} from "../styles/SettingAndStyles";
import {
  cancelRearrangeColumns,
  onContextMenuItemSelect,
  rearrangeColumns,
  reorderColumns,
  saveExcel,
} from "../utils/customFunctions";
import {
  DropDownListComponent,  
  MultiSelectComponent,
  MultiSelectChangeEventArgs
} from "@syncfusion/ej2-react-dropdowns";
import { CreateValueList, GetFileContent } from "./MFiles/MFilesService";
import { getColumnHeaders, getObjectPropertiesBL } from "./MFiles/PropertyHelper";
import { createLookup, enableToolbarItems, fileMerge, handleCreateNewObject, loadDataInDropDown, loadMemoryDataFromSheet, onCellEdit, onCellEditing, onClassChange,  onObjectTypeChange, refreshObjects, saveAsObject, saveInMFiles, selectHeader, setCellValueBL, transformSheet, transformToView } from "./BusinessLogic";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { spread } from "axios";
import { compareDates, compareDatesDesc, compareNumbers, compareNumbersDesc, compareStrings, compareStringsDesc, letterToNumber } from "../utils/util";

let arrangeModalDialog: any;
let alertDialog: any;
let alertErrorDialog: any;
let editDialog: any;
let dateTimeDialog: any;
let booleanDialog: any;
let dateDialog: any;
let singleSelectDialog: any;
let multiSelectDialog: any;
let valueListDialog: any;
let colHeaderDialog: any;
let IsLoaded: boolean = false;
let currentcolName: any;

let columnHeaders: any[] = []; //columns of the excel/spreadsheet
let arrangeColumnHeaders: any[] = []; //columns of the excel/spreadsheet
let arrangeListbox: any;
let currentColumnOrder: any[] = [];
let isSelectAllEvent: boolean = false;
let isSelectAdminEvent: boolean = false;
let tempDataSource: any[] = [];
let tempSelectedAdminText = "Select Admin";
// for save
let loader: any;
let isSave = true;
let selectedEvent = "";
// for save
let inputOpenFileRef: any;
//on select cell
let selectedclsId: any;
let selectedpropId: any;
let propDataType: any;
let propId: any;
let propobjTypeId: any;

//dateTime Dialog
let dateTimePickerObject: any;
let datePickerObject: any;
let dateValue: Date = new Date();

//boolean Dialog
let dropDownListBooleanObject: any;
let booleandropdownData: any[] = [];

//date Dialog

//single, multi select Dialog
let singleSelectListObject: any;
let multiSelectListObject: any;
let multiSelectDataItem: string;
 

//transform Dialog
let transformDialog: any;
let dropDownListVaultObject: any;
let dropDownListVaultObject1: any;
let dropDownListVaultClass: any;
let dropDownListVaultClass1: any;
let dropDownListUniqueProperty: any;
let dropDownColHeaderObject: any;
let vaultObjects: any[] = [];
let vaultClasses: any[] = [];
let vaultId: any;

let list1 = { text: "Name", value: "ID" };
let list2 = { text: "Name", value: "Name" };

export let transformedSheet: any[] = [];
export let currentRow: any;
export let currentCol: any;
export let adminColData: any[] = [];

window.ExistingPropIds = [];
window.AdminColumns = [];
window.SingleSelectProperties = [];
window.MultiSelectProperties = [];
window.LookupIds = [];
window.RequiredAdminColumns = [];
window.isAdminColumnsAddedForTrans = false;


// Define the columns for AdminColumns
const adminColumns = [
  "WebView",
  "vNextWebView",
  "DesktopView",
  "DesktopOpen",
  "Size On Server",
  "Last Modified",
  "GUID",
  "ID",
  "Object Type",
  "Object Name",
  "Object Class",
  "Class Name",
  "Modified",
  "Update Status",
  "Updated Message",
  "Created by",
  "Last modified by",
  "Version",
  "Workflow",
  "State"
];

// Initialize AdminColumns if it's empty
if (window.AdminColumns?.length === 0) {
  window.AdminColumns = adminColumns.map(title => ({ Title: title }));
}

// Define the columns for RequiredAdminColumns
const requiredAdminColumns = [
  "GUID",
  "ID",
  "Object Type",
  "Object Name",
  "Object Class",
  "Class Name",
  "Modified",
  "Update Status",
  "Updated Message"
];

// Initialize RequiredAdminColumns if it's empty
if (window.RequiredAdminColumns?.length === 0) {
  window.RequiredAdminColumns = requiredAdminColumns;
}

const Spreadsheet = () => {
  IsLoaded = false;
  const [selectedAllRows, setSelectedAllRows] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(true);
  const [hideUnHideAdmins, setHideUnHideAdmins] = useState(true);
  const [hideShowSelectRows, setHideShowSelectRows] = useState(false);
  const [columnsHaveHeaders, setColumnsHaveHeaders] = useState(true);

  const { isTablet, isIsLaptop } = useIsMobile();
  const spreadsheetRef = React.useRef<SpreadsheetComponent>(null);
  const [clickedColumnName, setClickedColumnName] = useState(null);
  const [vlItem, SetVlItem] = useState("");
  const navigate = useNavigate();
  inputOpenFileRef = React.createRef();

  window.ReorderColumnHeaders = [];
  const serverUrl = process.env.REACT_APP_BASEURL;
  vaultId = sessionStorage.getItem("vaultId")!;
  if (localStorage.getItem("token") !== null) {
    window.IsMFilesConnected = true;
  }

  let arrangeModalButtons = [
    {
      buttonModel: {
        content: "Apply",
        cssClass: "e-success",
        isPrimary: true,
      },
      click: () => {
        isSave = false;
        const argumentsData = {
          arrangeListbox,
          alertDialog,
          currentColumnOrder,
          spreadsheetRef,
          arrangeModalDialog,
          tempDataSource,
          isSelectAllEvent,
          columnHeaders,
          setHideUnHideAdmins,
          selectUnSelectAdminButton,
          setSelectedAdmin,
          tempSelectedAdminText,
        };
        rearrangeColumns(argumentsData);
        selectedEvent = "";
        if (columnsHaveHeaders===false) {
          setColumnsHaveHeaders(true)
        }
      },
    },
    {
      buttonModel: {
        content: "Cancel",
        cssClass: "btn-cancel",
        isPrimary: true,
      },
      click: () => {
        const cancelArguments = {
          isSelectAllEvent,
          selectUnSelectButton,
          setSelectedAllRows,
          arrangeModalDialog,
          tempDataSource,
          arrangeListbox,
          selectUnSelectAdminButton,
          setSelectedAdmin,
          setHideUnHideAdmins,
          isSelectAdminEvent
        };
        cancelRearrangeColumns(cancelArguments);
        if (columnsHaveHeaders===false) {
          setColumnsHaveHeaders(true)
        }
      },
    },
  ];

  let alertsButtons = [
    {
      buttonModel: {
        content: "OK",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: () => {
        alertDialog?.hide();
        alertErrorDialog?.hide();
        editDialog?.hide();
      },
    },
  ];

  let transformbuttons = [
    {
      buttonModel: {
        content: "Transform",
        cssClass: "e-success",
        isPrimary: true,
      },
      click: () => {
        isSave = false;
        transformSheet(spreadsheetRef.current, arrangeListbox, transformDialog, dropDownListVaultObject, dropDownListVaultClass, dropDownListUniqueProperty, loader);
        //isSave = true;
      },
    },
  ];

  let selectButtons: any = [
    {
      buttonModel: {
        content: "Submit",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: function click() {
        setCellValue();
        singleSelectDialog?.hide();
        multiSelectDialog?.hide();
        dateTimeDialog?.hide();
        dateDialog?.hide();
        booleanDialog?.hide();
        
      },
    },
  ];

  let selectDialogButtons: any = [
    {
      buttonModel: {
        content: 'New',
        cssClass: 'e-success',
        isPrimary: true,
      },
      'click': () => {
        if (selectedclsId == -1)//valuelist
        {
          valueListDialog.show();
        }
        else
        {
        createLookup(vaultId, propobjTypeId);
        }
      }
    },
    {
      buttonModel: {
        content: "Submit",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: function click() {
        setCellValue();
        singleSelectDialog?.hide();
        multiSelectDialog?.hide();
        dateTimeDialog?.hide();
        dateDialog?.hide();
        booleanDialog?.hide();
      },
    },
  ];

  let valueListButtons: any = [
    {
      buttonModel: {
        content: "Ok",
        cssClass: "e-flat",
        isPrimary: true,
      },
      click: function click() {
        CreateValueList(propobjTypeId, vlItem);
        valueListDialog.hide();
      },
    },
  ];

   
  let colHeaderButtons: any = [
    {
      buttonModel: {
        content: "Submit",
        cssClass: "e-flat", 
        isPrimary: true,
      },
      click: function click() {
        let spreadsheet = spreadsheetRef.current;
        let sheetName = spreadsheet?.getActiveSheet().name;
        let currentValue = getCell(
          currentRow,
          currentCol,
          spreadsheet?.getActiveSheet()!
        )?.value!;
        if (currentValue !== dropDownColHeaderObject.value) {
          spreadsheet?.updateCell(
            {
              value: dropDownColHeaderObject.text,
              index: dropDownColHeaderObject.value,
            },
            getCellAddress(currentRow, currentCol)
          ); //  to specify the cell value, range
        }
        if (adminColData.find((x) => x.SheetName === sheetName) === undefined) {
          adminColData.push({
            ObjTypeList: dropDownListVaultObject.dataSource,
            Cls: dropDownListVaultClass1.value,
            ObjType: dropDownListVaultObject.value,
            SheetName: spreadsheet?.getActiveSheet().name,
          });
        } else {
          var tmp = adminColData.find((x) => x.SheetName === sheetName);
          tmp.Cls = dropDownListVaultClass1.value;
          tmp.ObjType = dropDownListVaultObject.value;
        }

        let newcolName = dropDownColHeaderObject.text;
        var tmpHeader = window.ReorderColumnHeaders?.find(
          (x) => x.SheetName === sheetName && x.Title === currentValue
        )!;

        var tmpHeader1 = window.ReorderColumnHeaders?.find(
          (x) => x.SheetName === sheetName && x.Title === newcolName
        )!;
        var tmpHeaderIndex1 = window.ReorderColumnHeaders?.findIndex(
          (x) => x.SheetName === sheetName && x.Title === newcolName
        )!;
        if (tmpHeader !== undefined) {
          tmpHeader.Title = newcolName;
        }
        if (tmpHeader1 !== undefined) {
          window.ReorderColumnHeaders?.splice(tmpHeaderIndex1, 1);
          //tmpHeader1.Title = currentValue;
        }
        var index = window.ColumnHeaders?.indexOf(currentValue);

        if (index !== -1 && index !== undefined) {
          window.ColumnHeaders[index] = newcolName;
        }

        let memoryData = window.SheetMemoryData?.find(
          (x) => x.SheetName === sheetName
        )?.MemData;
        if (memoryData === undefined) {
          //sheet not yet transformed and class not yet defined
        }
        memoryData?.forEach(function (row, i) {
          row[newcolName] = row[currentValue];
          // console.log(row);
        });
        colHeaderDialog.hide();
      },
    },
  ];

  const contextMenuArguments = {
    spreadsheetRef,
    clickedColumnName,
    arrangeColumnHeaders,
    arrangeListbox,
    setClickedColumnName,
  };

  
  const handleConnectServer = () => {
    sessionStorage.setItem("server", "null");
    sessionStorage.setItem("fileId", "null");
    sessionStorage.setItem("isServerConnect", "true");
    navigate("/serverconnection");
  };

  useEffect(() => {
    let spreadsheet = spreadsheetRef.current;
    spreadsheet?.addFileMenuItems(
      [{ text: "Add New File", iconCss: "e-open e-icons" }],
      "Open",
      false
    );
    spreadsheet?.addRibbonTabs([
      {
        header: { text: "M-Files" },
        content: [
          {
            text: "Update View Report",
            tooltipText: "Update View Report",
            prefixIcon: "e-save",
            click: () => {
              isSave = false;
              saveInMFiles(spreadsheetRef?.current);
            },
          },
          {
            text: "Update M-Files Objects",
            tooltipText: "Update M-Files Objects",
            prefixIcon: "e-save",
            click: () => {
              saveAsObject(spreadsheetRef?.current, alertErrorDialog);
            },
          },
          {
            text: "Transform To View",
            tooltipText: "Transform To View",
            prefixIcon: "e-save",
            click: () => {
              transformToView(spreadsheet, dropDownListVaultObject, dropDownListUniqueProperty, transformDialog);
            },
          },
          {
            text: "Connect To Server",
            tooltipText: "Connect To Server",
            prefixIcon: "e-save",
            click: () => {
              handleConnectServer();
            },
          },          
          {
            text: "Create New Object",
            tooltipText: "Create New Object",
            prefixIcon: "e-save",
            click: () => {
              if (window.IsMFilesConnected == undefined || window.IsMFilesConnected == false) {
                return;
              }
              handleCreateNewObject(spreadsheetRef?.current);
            },
          },          
        ],
      },
      {
        header: { text: "Help" },
        content: [
          {
            text: "About View Maven",
            tooltipText: "About View Maven",
            prefixIcon: "e-paste",
            showAlwaysInPopup: true,
            click: () => {
              //help();
            },
          },
        ],
      },
    ]);
    spreadsheet?.addToolbarItems("View", [
      {
        text: "Arrange Columns",
        tooltipText: "Arrange Columns",
        prefixIcon: "e-sorting-1",
        click: () => {
          const argumentValue = {
            spreadsheetRef,
            columnHeaders,
            arrangeColumnHeaders,
            arrangeListbox,
            setHideShowSelectRows,
            tempDataSource,
            arrangeModalDialog,
            selectedEvent
          };
          reorderColumns(argumentValue);
        },
      },
    ]);
  }, []);


  // show custom spinner when save file
  const onCreated = async() => {
    if (IsLoaded) return;
    window.Host = (sessionStorage.getItem("host") + "/REST/").toString();
    let spreadsheet = spreadsheetRef?.current;
    spreadsheet?.element
    .querySelector(".e-sheet-panel")
    ?.addEventListener("click", function (event) {
      let target: HTMLElement = event.target as HTMLElement;
      if (target && target.classList.contains("e-cell")) {
        currentRow = target.parentElement!.getAttribute("aria-rowindex");
        currentCol = target.getAttribute("aria-colindex");
        onSelect(currentRow - 1, currentCol - 1);
      }
    });
    if (window.IsMFilesConnected){
      spreadsheet?.enableToolbarItems("M-Files", [0], false);
      spreadsheet?.enableToolbarItems("M-Files", [1], false);
      spreadsheet?.enableToolbarItems("M-Files", [2], true);  
      spreadsheet?.enableToolbarItems("M-Files", [3], true);  
      spreadsheet?.enableToolbarItems("M-Files", [4], false); 
    }
    else{
    spreadsheet?.enableToolbarItems("M-Files", [0], false);
    spreadsheet?.enableToolbarItems("M-Files", [1], false);
    spreadsheet?.enableToolbarItems("M-Files", [2], false);  
    spreadsheet?.enableToolbarItems("M-Files", [3], true);  
    spreadsheet?.enableToolbarItems("M-Files", [4], false); 
    }
    loader = spreadsheet?.createElement("div", {
      className: "e-custom-spinner e-spinner-pane e-spin-hide",
    });
    loader.appendChild(
      spreadsheet?.createElement("div", { className: "e-ss-custom-loader" })
    );
    spreadsheet?.element?.appendChild(loader);
    let docId = sessionStorage.getItem("docId")!;
    let fileId = sessionStorage.getItem("fileId")!;
    if (docId === null || fileId === null) return;
    let response1 = await GetFileContent(0, docId, fileId);
    if (response1 === undefined || response1 === null) return;
    if (response1.status === 403){
      navigate("/serverconnection");
    }
    const fileBlob = await response1.blob();
    try {
      var file = new File([fileBlob], "Sample.xlsx"); //convert the blob into file
      if (spreadsheet) {
        spreadsheet.open({ file: file }); // open the file into Spreadsheet
      }
    } catch (error) {
      console.log("Error in opening the View report : " + error);
    }  
  }

  function setCellValue() {
    let spreadsheet = spreadsheetRef?.current;
  
    switch (propDataType) {
      case 9:
        setCellValueBL(spreadsheet, singleSelectListObject.value, singleSelectListObject.text);
        break;
      case 10:
        let value = multiSelectDataItem?.slice(0, -1);
        setCellValueBL(spreadsheet, value, value);
        break;
      case 7:
        setCellValueBL(spreadsheet, dateTimePickerObject.previousElementValue, dateTimePickerObject.previousElementValue);
        break;
      case 5:
        setCellValueBL(spreadsheet, datePickerObject.previousElementValue, datePickerObject.previousElementValue);
        break;
      case 8:
        setCellValueBL(spreadsheet, dropDownListBooleanObject.value, dropDownListBooleanObject.text);
        break;
      default:
        console.warn(`Unhandled propDataType: ${propDataType}`);
        break;
    }
  }

  async function onSelect(row: any, col: any) {
    let spreadsheet = spreadsheetRef?.current;
    if (window.IsMFilesConnected == undefined || window.IsMFilesConnected == false) {
      return;
    }
    let currentSheetName = spreadsheet?.getActiveSheet().name;
    //singleSelectListObject!.value = null;
   // multiSelectListObject!.value = null;

    currentRow = row;   
    currentCol = col;
     //if cell is clicked
     let cell1: CellModel = getCell(
      0,
      currentCol,
      spreadsheet!.getActiveSheet()
    ); // to get the column name
    let colName = cell1?.value;
    currentcolName = colName;
    let memoryData = window.SheetMemoryData?.find(
      (x) => x.SheetName === currentSheetName
    )?.MemData;
    if (currentRow === 0) {
      console.log(colName);
      selectHeader(currentSheetName, dropDownListVaultObject1, dropDownColHeaderObject, colHeaderDialog, colName);
      return;
    }
   
    let cell2: CellModel = getCell(
      currentRow,
      currentCol,
      spreadsheet!.getActiveSheet()
    );
    let cellValue = spreadsheet!.getDisplayText(cell2); // to get the cell value
    let prop = window.ExistingPropIds?.find((x) => x.Name === colName);
    if ((prop == undefined && currentCol != 0) || prop == undefined) {
      //if the column is not a property column
      //spreadsheet!.updateCell({ value: "Property " + colName + " does not belong to the object class" }, getCellAddress(range[0], columnHeaders.indexOf("UpdatedMessage")));
      return;
    }
    propId = prop.Id;
    propDataType = prop.DataType;
    var item = memoryData && memoryData[currentRow - 1];
    if (propDataType === 9 || propDataType === 10) {
      selectedpropId = propId;      
      // Use a mapping object to avoid duplicate code
      const propertyMap = {
        9: window.SingleSelectProperties,
        10: window.MultiSelectProperties
      };
      
      const properties = propertyMap[propDataType];      
      // Retrieve the ClsId based on propId
      selectedclsId = properties.find((x) => x.Id == propId)?.ClsId;  
      propobjTypeId = properties.find((x) => x.Id == propId)?.ObjTypeId;  
      loadDataInDropDown(prop.Name, propId, propDataType, true, singleSelectListObject, multiSelectListObject, singleSelectDialog, multiSelectDialog, cellValue);
    } else if (propDataType === 7) {//Timestamp
      dateTimePickerObject.value = cellValue;
      dateTimeDialog.show();
    } else if (propDataType === 5) {//date
      datePickerObject.value = cellValue;
      dateDialog?.show();
    } else if (propDataType === 8) {
      booleandropdownData = [];
      booleandropdownData.push({ Title: "False", Id: 1 });
      booleandropdownData.push({ Title: "True", Id: 2 });
      dropDownListBooleanObject.fields = { text: "Title", value: "Id" };
      dropDownListBooleanObject.dataSource = booleandropdownData;
      booleanDialog.header = colName + " List";
      booleanDialog.show();
    }
  }
  
  const beforeSave = (args: any) => {
    if (isSave) {
      args.cancel = true; // Preventing default save action.
      //Show the custom spinner.
      classList(loader, ["e-spin-show"], ["e-spin-hide"]);
      isSave = false; // The beforeSave event will trigger for saveAsJson action also, so we are preventing for the saveAsJson using the variable.
      //Used setTimeout to hide the save dialog and show the spinner.
     // setTimeout(() => {
        saveExcel(args, spreadsheetRef, isSave, loader);
      //}, 450);
    }
  };

  const onOpenComplete = async (args: any) => {
    if (IsLoaded) return;
    IsLoaded = true;
    let spreadsheet = spreadsheetRef.current;
    window.SheetMemoryData = [];
    window.StyleMemoryData = [];
    window.ExistingPropIds = [];
    classList(loader, ["e-spin-show"], ["e-spin-hide"]);
    //  for load new style from db 
    setTimeout(async () => {
      let sheetName = spreadsheet?.getActiveSheet().name;
      let colIndex = spreadsheet?.getActiveSheet().usedRange?.colIndex!;
      let rowIndex = spreadsheet?.getActiveSheet().usedRange?.rowIndex!;
      let colHeaderText = getColumnHeaderText(colIndex + 1);

      //unHide Admin columns
      for (let i = 0; i <= colIndex; i++) {
        //let cellValue = getCell(0, i, spreadsheet?.getActiveSheet()!)?.value;
       // if (adminColumns.indexOf(cellValue!) !== -1) {
          spreadsheet?.hideColumn(i, i, false);
       // }       
      }

      spreadsheet!.activeSheetIndex = 0;
      let columnRange: string = getColumnHeaderText(1) + ":" + colHeaderText;
      spreadsheet?.setColumnsWidth(120, [columnRange]);
      
      // default style for header
      spreadsheet?.cellFormat(
        ColumnHeaderStyle,
        sheetName + "!A1:" + colHeaderText + "1"
      );
      // default style for cells
      let columnRange2: string = getColumnHeaderText(1) + "2:" + colHeaderText + rowIndex;
      var tmp2 = sheetName + "!" + columnRange2;
      spreadsheet?.cellFormat(
        ColumnStyle,
        tmp2
      );
     
      await getColumnHeaders(spreadsheet?.getActiveSheet().index, spreadsheet);
      let colHeaders = window.ReorderColumnHeaders?.filter(
        (x) => x.SheetName === sheetName
      )
      enableToolbarItems(spreadsheet, colHeaders);
     
      if (window.ColumnHeaders?.indexOf("Object Class") !== -1) {    
        await getObjectPropertiesBL(spreadsheet, spreadsheet?.getActiveSheet().index);  
        //create an In-Memory variable for the data
        isSave = false;
        await loadMemoryDataFromSheet(spreadsheet, 0, false, true, loader); 
        transformedSheet.push(spreadsheet?.getActiveSheet().name);   
       // spreadsheet?.freezePanes(1, 0);
      }
      else{
        classList(loader, ["e-spin-hide"],["e-spin-show"]);
      }
    });
   
    spreadsheet?.element
    .querySelector(".e-sheet-panel")
    ?.addEventListener("click", function (event) {
      let target: HTMLElement = event.target as HTMLElement;
      if (target && target.classList.contains("e-cell")) {
        currentRow = target.parentElement!.getAttribute("aria-rowindex");
        currentCol = target.getAttribute("aria-colindex");
        onSelect(currentRow - 1, currentCol - 1);
      }
    });
  };

  const actionBegin = async (args: any) => {
    let spreadsheet = spreadsheetRef?.current;
    let sheetName = spreadsheet?.getActiveSheet().name;
    let memoryData = window.SheetMemoryData?.find(
      (x) => x.SheetName === sheetName
    )?.MemData;
    if (args.action === "autofill") {
      let range: number[] = getRangeIndexes(args.args.eventArgs.fillRange);
      let startRow = range[0];
      let endRow = range[2];
      for (let i = startRow; i <= endRow; i++) {
        if (window.ColumnHeaders?.indexOf("Modified") !== -1)
          spreadsheet?.updateCell(
            { value: "True" },
            getCellAddress(i, window.ColumnHeaders?.indexOf("Modified"))
          );
        let item = memoryData && memoryData[i - 1];
        if (item !== undefined) item["Modified"] = "True";
      }
    }
    if (args.action === "beforeOpen") {
      spreadsheet?.enableToolbarItems("M-Files", [0], true);
      spreadsheet?.enableToolbarItems("M-Files", [2], false);
    }
    if (args.action === "gotoSheet") {
      arrangeListbox.dataSource = [];
      arrangeColumnHeaders = [];     
      let sheetName =
        spreadsheet?.sheets[args.args.eventArgs.currentSheetIndex].name;
        await getColumnHeaders(args.args.eventArgs.currentSheetIndex, spreadsheet);
        let colHeaders = window.ReorderColumnHeaders?.filter(
        (x) => x.SheetName === sheetName
      );
      if (window.IsMFilesConnected){
        if (colHeaders !== undefined && colHeaders.length > 0) {
          var index = colHeaders.findIndex((x) => x.Title == "Object Class") && colHeaders.findIndex((x) => x.Title == "Object Type");
          if (index !== -1)//Object Type is defined for the sheet
          {
            spreadsheet?.enableToolbarItems("M-Files", [0], true);
            spreadsheet?.enableToolbarItems("M-Files", [1], true);
            spreadsheet?.enableToolbarItems("M-Files", [2], false);  
            spreadsheet?.enableToolbarItems("M-Files", [3], true);  
            spreadsheet?.enableToolbarItems("M-Files", [4], true); 
          }
          else
          {
            spreadsheet?.enableToolbarItems("M-Files", [0], false);
            spreadsheet?.enableToolbarItems("M-Files", [1], false);
            spreadsheet?.enableToolbarItems("M-Files", [2], true);  
            spreadsheet?.enableToolbarItems("M-Files", [3], true);  
            spreadsheet?.enableToolbarItems("M-Files", [4], false); 
          }
        }
      }
      else{
        spreadsheet?.enableToolbarItems("M-Files", [0], false);
        spreadsheet?.enableToolbarItems("M-Files", [1], false);
        spreadsheet?.enableToolbarItems("M-Files", [2], false);  
        spreadsheet?.enableToolbarItems("M-Files", [3], true);  
        spreadsheet?.enableToolbarItems("M-Files", [4], false);  
      }
       if (transformedSheet.indexOf(sheetName) !== -1) {
        await getObjectPropertiesBL(spreadsheet, args.args.eventArgs.currentSheetIndex);
         spreadsheet?.enableToolbarItems("M-Files", [2], false);
         isSave = false;
         loadMemoryDataFromSheet(spreadsheet, 
           args.args.eventArgs.currentSheetIndex,
           false,
           false, loader           
         );
         // let item: any = memoryData && memoryData[0];
         // g_obj_type_id = item && item["Object Type"];
         // g_obj_type_name = item && item["Object Name"];
         // g_cls_id = item && item["Object Class"];
         // g_cls_name = item && item["Class Name"];
       }
       dropDownListVaultClass!.value = null;
       dropDownListVaultClass1!.value = null;
       dropDownListVaultObject!.value = null;
       dropDownListVaultObject1!.value = null;
     }
  };


  const onActionComplete = async (args: any) => {
    let spreadsheet = spreadsheetRef.current;

    if (args.action === "import") {
      arrangeListbox.dataSource = [];
      arrangeColumnHeaders = [];
    }

    // Check if any columns have been unhidden
    if (args.action === "resize" || args.action === "resizeToFit") {
      const sheet: any = spreadsheet?.sheets[spreadsheet?.activeSheetIndex];
      const colName = sheet?.rows[0]?.cells[args?.eventArgs?.index]?.value;
      if (colName) {
        arrangeColumnHeaders.map((items) => {
          if (items.Title === colName) {
            items.Visible = true;
          }
          return items;
        });
        arrangeListbox.dataSource.map(
          (items: { Title: any; Visible: boolean }) => {
            if (items.Title === colName) {
              items.Visible = true;
            }
            return items;
          }
        );
        selectedEvent = "";
      }
    }
  };

  // Handler for file menu item click event
  const handleFileMenuItemClick = (args: any, inputOpenFileRef: any) => {
    let spreadsheet = spreadsheetRef?.current;
    switch (args.item.text) {
      case "New":
        // Wait for the dialog to appear and then customize it
        setTimeout(() => {
          const dialog = document.querySelector(".e-dialog");
          const okButton = dialog?.querySelector(".e-primary");

          if (okButton) {
            okButton.addEventListener("click", handleOkButtonClick);
          }
        }, 0);
        break;
      case "Save":
        if (spreadsheet) {
          spreadsheet?.save({
            fileName: spreadsheet?.getActiveSheet()?.name,
            saveType: "Xlsx",
          });
        }
        break;
        case "Add New File":
          inputOpenFileRef.current.click();
          break;
      default:
        break;
    }
  };

  const handleOkButtonClick = () => {
    arrangeListbox.dataSource = [];
    arrangeColumnHeaders = [];
  };

  const onContextMenuBeforeOpen = (args: BeforeOpenCloseMenuEventArgs) => {
    let spreadsheet = spreadsheetRef?.current;

    const target: any = (args.event.target as HTMLElement).closest(
      "th.e-header-cell"
    );
    if (target) {
      const colIndex = getColumnIndex(target);
      const sheet: any = spreadsheet?.sheets[spreadsheet?.activeSheetIndex];
      const colName = sheet?.rows[0]?.cells[colIndex]?.value;

      if (colName !== undefined && colName !== null) {
        setClickedColumnName(colName);
      }
    }
  };

  const getColumnIndex = (target: HTMLElement) => {
    const colIndexAttr = target.getAttribute("aria-colindex");
    return colIndexAttr ? parseInt(colIndexAttr, 10) - 1 : -1;
  };

  const selectUnSelectButton = useMemo(() => {
    return selectedAllRows ? "Select All" : "Unselect All";
  }, [selectedAllRows]);

  const selectUnSelectAdminButton = useMemo(() => {
    if (!selectedAdmin) {
      return "Unselect Admin";
    } else {
      return "Select Admin";
    }
  }, [selectedAdmin]);

  const dialogClose = () => {
    alertDialog?.hide();
  };

  // for arrange modal checkbox
  const onCheckboxChange = (event: { target: any }) => {
    const target = event.target;
    const rowElement = target?.closest("tr");
    const rowIndex = rowElement ? rowElement?.rowIndex : null;
    if (
      rowIndex !== null &&
      rowIndex >= 0 &&
      rowIndex < arrangeListbox?.dataSource?.length
    ) {
      if (arrangeListbox.dataSource[rowIndex].PropertyLabel === "O"){
        if(target.checked == false)
        {
          alert('Obligatory columns cannot be hidden');
        }
        target.checked = true;
      }
      arrangeListbox.dataSource[rowIndex].Visible = target?.checked;
      arrangeListbox.dataSource[rowIndex].Hidden = !target?.checked;
    }
  };

  document.addEventListener("change", function (event: any) {
    if (event?.target?.classList?.contains("visibility-checkbox")) {
      onCheckboxChange(event);
    }
  });

  const unselectAllRows = () => {
    setSelectedAllRows((prevState) => !prevState);
    setSelectedAdmin(true);
    isSelectAllEvent = true;
    (arrangeListbox.dataSource as object[]).forEach(
      (arrangeItems: any, index) => {
        arrangeItems["Visible"] = selectedAllRows;
      }
    );
    arrangeListbox.refresh();
  };

  const selectAllRows = () => {
    setSelectedAllRows((prevState) => !prevState);
    isSelectAllEvent = true;
    (arrangeListbox.dataSource as object[]).forEach(
      (arrangeItems: any, index) => {
        arrangeItems["Visible"] = selectedAllRows;
      }
    );
    arrangeListbox.refresh();
  };

  const selectAllAdminRows = async ()=> {
    setSelectedAdmin((prevState) => !prevState);
    isSelectAdminEvent = true;
    (arrangeListbox.dataSource as object[]).forEach((sdata, index) => {
      if (sdata["IsAdmin"] === true) {
        sdata["Visible"] = selectedAdmin;
      }
    });
    arrangeListbox.refresh();
  }

  const unselectAllAdminRows = async () => {
    setSelectedAdmin((prevState) => !prevState);
    isSelectAdminEvent = true;
    (arrangeListbox.dataSource as object[]).forEach((sdata, index) => {
      if (sdata["IsAdmin"] === true) {
        sdata["Visible"] = selectedAdmin;
      }
    });
    arrangeListbox.refresh();
  }

  let hiddenRows: any[] = [];
  const hideAndUnHideAllAdminRows = async () => {
    setHideUnHideAdmins((prevState) => !prevState);
    if (hideUnHideAdmins === true) {
      (arrangeListbox.dataSource as object[]).forEach((sdata: any, index) => {
        if (sdata["IsAdmin"] === true) {
          arrangeListbox.getRowByIndex(index).style.display = "none"; //hide row
          hiddenRows.push(index); // add row index to hiddenRows array
        }
      });
    }

    if (hideUnHideAdmins === false) {
      hiddenRows.forEach((rowIndex) => {
        arrangeListbox.getRowByIndex(rowIndex).style.display = "";
      });
      hiddenRows = [];
      arrangeListbox.refresh();
    }
  }

  const onMultiSelect = (args: MultiSelectChangeEventArgs) => {
    multiSelectDataItem = "";
    args.value.forEach((data: any) => {
      multiSelectDataItem += data + ";";
    });
  };

  // for sorting
  const onBeforeSort = (args): void => {
    let spreadsheet = spreadsheetRef.current;
    let actCellIdxs = getRangeIndexes(args.range);
    args.range =
      spreadsheet?.getActiveSheet().name +
      "!" +
      getRangeAddress([actCellIdxs[0], actCellIdxs[1]]);

  };

  const onSortComplete = (args) => {
    let sortField = args.sortOptions.sortDescriptors.field;
    let sortOrder = args.sortOptions.sortDescriptors.order;
    let spreadsheet = spreadsheetRef.current;
    let sheetName = spreadsheet!.getActiveSheet().name;
    let fieldName = getCell(
      0,
      letterToNumber(sortField) - 1,
      spreadsheet!.getActiveSheet()
    )?.value!;
    let dataType = window.ExistingPropIds.find((x) => x.Name === fieldName)?.DataType;
    if (window.SheetMemoryData?.find((x) => x.SheetName === sheetName) !== undefined) {
      var tmpMemory = window.SheetMemoryData?.find((x) => x.SheetName === sheetName)!;
      if (tmpMemory !== undefined) {
        var tmpMemData = tmpMemory.MemData;
        //console.log('Input Data:', tmpMemData);
        if (sortOrder === "Ascending") {
          if (dataType === 5) {
            tmpMemData.sort(function (a, b) {
              return compareDates(a[fieldName], b[fieldName]);
            });
          }
          else {
            if (fieldName === "ID" || dataType === 2) {
              tmpMemData.sort(function (a, b) {
                return compareNumbers(a[fieldName], b[fieldName]);
              });
            } else {
              const sortedArray = tmpMemData.filter(x => x[fieldName] !== "").sort(function (a, b) {
                return compareStrings(a[fieldName], b[fieldName]);
              }).concat(tmpMemData.filter(x => x[fieldName] === ""));
              tmpMemData = sortedArray;
            }
          }
        } else {
          if (dataType === 5) {
            tmpMemData.sort(function (a, b) {
              return compareDatesDesc(a[fieldName], b[fieldName]);
            });
          }
          else {
            if (fieldName === "ID" || dataType === 2) {
              tmpMemData.sort(function (a, b) {
                return compareNumbersDesc(a[fieldName], b[fieldName]);
              });
            } else {
              const sortedArrayDesc = tmpMemData.filter(x => x[fieldName] !== "").sort(function (a, b) {
                return compareStringsDesc(a[fieldName], b[fieldName]);
              }).concat(tmpMemData.filter(x => x[fieldName] === ""));
              tmpMemData = sortedArrayDesc;
            }
          }
        }
        //tmpMemData.reverse();
       // console.log('Output Data:', tmpMemData);
        tmpMemory.MemData = tmpMemData;
      }
    }
  };

  const onHandleColumnCheckBox = (args: any)=> {
    setColumnsHaveHeaders(args.checked)
    arrangeListbox.columns = arrangeListbox?.columns.map((column) => { 
      if (column?.headerText==="Header") {
        column.visible = args.checked
      }
      return column
    })
  }

  return (
    <div className="App spinner-target">
      <DialogComponent
        ref={(dialog) => (alertDialog = dialog)}
        allowDragging={true}
        width="400px"
        header="Alert Message"
        animationSettings={settings}
        buttons={alertsButtons}
        visible={false}
        showCloseIcon={true}
        close={dialogClose}
      >
        <div className="alertDialog">
          <p>Please select atleast one column to display the view !!</p>
        </div>
      </DialogComponent>
      <DialogComponent
        ref={(dialog) => (alertErrorDialog = dialog)}
        allowDragging={true}
        width="400px"
        header="Alert Message"
        animationSettings={settings}
        buttons={alertsButtons}
        visible={false}
        showCloseIcon={true}
        close={dialogClose}
      >
        <div id="alertdialog">
          <p>Obligatory values missing in one or more objects !!</p>
        </div>
      </DialogComponent>
      <DialogComponent
        ref={(dialog) => (editDialog = dialog)}
        allowDragging={true}
        width="400px"
        header="Alert Message"
        animationSettings={settings}
        buttons={alertsButtons}
        visible={false}
        showCloseIcon={true}
        close={dialogClose}
      >
        <div id="alertdialog-big-font">
          <p>You cannot edit this column !!</p>
        </div>
      </DialogComponent>
      <DialogComponent
        ref={(dialog) => (arrangeModalDialog = dialog)}
        enableResize={true}  resizeHandles={['All']}
        allowDragging={true}
        animationSettings={settings}
        id="arrange-dialog"
        showCloseIcon={true}
        position={firstPosition}
        buttons={arrangeModalButtons}
        visible={false}
        header={"Arrange Columns"}
        target={"#target"}
        minHeight={isTablet ? 650 : isIsLaptop ? 670 : 660}
      >
        <div className="arrange-top-button">
          {!hideShowSelectRows && (
            <>
            <ButtonComponent
              type="submit"
              cssClass="e-primary"
              onClick={() =>
                selectedAllRows ? selectAllRows() : unselectAllRows()
              }
              content={selectUnSelectButton}
            />
            <TooltipComponent content="Columns have Headers" position={'BottomCenter'} tabIndex={0} style={{ display: 'block', }}>
              <CheckBoxComponent checked={columnsHaveHeaders}
              change={onHandleColumnCheckBox} />
            </TooltipComponent>
            </>
          )}
          {
            window.IsMFilesConnected && (
              <>
                <ButtonComponent
                  type="submit"
                  cssClass="e-primary"
                  onClick={() =>
                    selectedAdmin ? selectAllAdminRows() : unselectAllAdminRows()
                  }
                  // content={selectUnSelectAdminButton}
                  content={"Select / Unselect Admin"}
                />
                <ButtonComponent
                  type="submit"
                  cssClass="e-primary"
                  onClick={() => hideAndUnHideAllAdminRows()}
                  // content={hideUnHideAdminButton}
                  content={"Hide / Unhide Admin"}
                />
              </>
            )
            
          }
        </div>
        <div id="reorder">
          <GridComponent
            ref={(scope) => {
              arrangeListbox = scope;
            }}
            height={isTablet ? 350 : isIsLaptop ? 400 : 380}
            selectionSettings={{ type: "Multiple", checkboxMode: "Default" }}
            allowRowDragAndDrop={true}
          >
            <Inject services={[RowDD, Selection]} />
          </GridComponent>
        </div>
      </DialogComponent>
      <DialogComponent
        ref={(dialog) => (transformDialog = dialog)}
        width="340px"
        header={"Transform To View"}
        id="dialog9" allowDragging={true}
        position={firstPosition}
        visible={false}
        showCloseIcon={true}
        animationSettings={settings}
        buttons={transformbuttons}
        minHeight={"auto"}
      >
        <DropDownListComponent
          ref={(scope) => {
            dropDownListVaultObject = scope;
          }}
          change={(arg)=>onObjectTypeChange(arg, spreadsheetRef?.current, dropDownListVaultClass, dropDownListVaultClass1, adminColData)}
          dataSource={vaultObjects}
          placeholder="Select any object type"
          fields={list1}
        />
        <DropDownListComponent
          ref={(scope) => {
            dropDownListVaultClass = scope;
          }}
          dataSource={vaultClasses}
          change={(arg)=>onClassChange(arg, dropDownListUniqueProperty, dropDownColHeaderObject, currentcolName)}
          placeholder="Select any class"
          fields={list1}
        />
        <DropDownListComponent
          ref={(scope) => {
            dropDownListUniqueProperty = scope;
          }}
          enabled={false}
          placeholder="Select any unique property"
          fields={list1}
        />
      </DialogComponent>
      
      <DialogComponent
        ref={(dialog) => (dateTimeDialog = dialog)}
        allowDragging={true}
        width="340px"
        header={"Data List"}
        animationSettings={settings}
        id="dialog2"
        position={firstPosition}
        visible={false}
        showCloseIcon={true}
        buttons={selectButtons}
        minHeight={"auto"}
      >
        <DateTimePickerComponent
          id="datetimepicker"
          value={dateValue}
          ref={(scope) => {
            dateTimePickerObject = scope;
          }}
          placeholder="Select a date and time"
          format={"dd/MM/yyyy HH:MM a"}
        ></DateTimePickerComponent>
      </DialogComponent>
      <DialogComponent
        ref={(dialog) => (booleanDialog = dialog)}
        allowDragging={true}
        width="340px"
        header={"Data List"}
        id="dialog"
        position={firstPosition}
        visible={false}
        showCloseIcon={true}
        animationSettings={settings}
        buttons={selectButtons}
        minHeight={"auto"}
      >
        <DropDownListComponent
          id="ddlelement"
          ref={(scope) => {
            dropDownListBooleanObject = scope;
          }}
          dataSource={booleandropdownData}
          placeholder="Select any value"
          fields={list2}
        />
      </DialogComponent>
      <DialogComponent
        ref={(dialog) => (dateDialog = dialog)}
        allowDragging={true}
        width="340px"
        animationSettings={settings}
        id="dialog11"
        header={"Select a date"}
        position={firstPosition}
        visible={false}
        showCloseIcon={true}
        buttons={selectButtons}
        minHeight={"auto"}
      >
        <DatePickerComponent
          id="datepicker"
          value={dateValue}
          ref={(scope) => {
            datePickerObject = scope;
          }}
          placeholder="Select a date"
          format={"dd/MM/yyyy"}
        ></DatePickerComponent>
      </DialogComponent>
      <DialogComponent
        ref={(dialog) => (singleSelectDialog = dialog)}
        allowDragging={true}
        width="340px"
        header={"Data List"}
        id="dialog"
        position={firstPosition}
        visible={false}
        showCloseIcon={true}
        animationSettings={settings}
        buttons={selectDialogButtons}
        target={"#target"}
        minHeight={"auto"}
      >
        <div className="policies-list-content">
          <DropDownListComponent
            id="ddlelement"
            ref={(scope) => {
              singleSelectListObject = scope;
            }}
            //dataSource={dropdownData}
            placeholder="Select any value"
            fields={list2}
          />

          <ButtonComponent className="refresh-icon-btn"
            cssClass="e-primary" iconCss="e-icons e-refresh" onClick={() => refreshObjects(selectedpropId, singleSelectListObject, multiSelectListObject)} ></ButtonComponent>
        </div>

      </DialogComponent>

      <DialogComponent
        ref={(dialog) => (multiSelectDialog = dialog)}
        allowDragging={true}
        width="340px"
        id="dialog1"
        isModal={true}
        header="Data List"
        buttons={selectDialogButtons}
        position={firstPosition}
        visible={false}
        showCloseIcon={true}
        animationSettings={settings} 
        target={"#target"}
        minHeight={"auto"}
      >
        <div className="policies-list-content">
          <MultiSelectComponent
            id="ddlelement1"
            onChange={onMultiSelect}
            ref={(scope) => {
              multiSelectListObject = scope;
            }}
            placeholder="Select any value"
            mode="Delimiter"
            delimiterChar=";"
          />
          <ButtonComponent className="refresh-icon-btn"
            cssClass="e-primary" iconCss="e-icons e-refresh" onClick={() => refreshObjects(selectedpropId, singleSelectListObject, multiSelectListObject)} ></ButtonComponent>
        </div>
      </DialogComponent>
      <DialogComponent
        ref={(dialog) => (valueListDialog = dialog)}
        width="340px" allowDragging={true}
        animationSettings={settings}
        id="dialog11"
        header={"Add Valuelist Item"}
        position={firstPosition}
        visible={false}
        showCloseIcon={true}
        buttons={valueListButtons}
        minHeight={"auto"}
      >
        <TextBoxComponent
          value={vlItem}
          onChange={(e) => SetVlItem(e.value)}
          className="e-input"
        />
      </DialogComponent>
      <DialogComponent
        ref={(dialog) => (colHeaderDialog = dialog)}
        allowDragging={true}
        width="340px"
        header={"Select Column Header"}
        id="dialog10"
        position={firstPosition}
        visible={false}
        showCloseIcon={true}
        animationSettings={settings}
        buttons={colHeaderButtons}
        minHeight={"auto"}
      >
        <DropDownListComponent
          ref={(scope) => {
            dropDownListVaultObject1 = scope;
          }}
          cssClass="e-custom"
          change={(arg)=>onObjectTypeChange(arg, spreadsheetRef?.current, dropDownListVaultClass, dropDownListVaultClass1, adminColData)}
          dataSource={vaultObjects}
          id="object1"
          placeholder="Select any object type"
          fields={list1}
        />
        <DropDownListComponent
          ref={(scope) => {
            dropDownListVaultClass1 = scope;
          }}
          cssClass="e-custom1"
          dataSource={vaultClasses}
          change={(arg)=>onClassChange(arg, dropDownListUniqueProperty, dropDownColHeaderObject, currentcolName)}
          id="class1"
          placeholder="Select any class"
          fields={list1}
        />
        <DropDownListComponent
          ref={(scope) => {
            dropDownColHeaderObject = scope;
          }}
          placeholder="Select any property"
          fields={list1}
        />
      </DialogComponent>

      <input
        ref={inputOpenFileRef}
        type="file"
        onChange={(e) => fileMerge(e, spreadsheetRef?.current, inputOpenFileRef)}
        style={{ display: "none" }}
      />
      <SpreadsheetComponent
        id="spreadsheet"
        cellStyle={cellStyle}
        className="spreadsheet-component"
        ref={spreadsheetRef}
        allowOpen={true} 
        openUrl={`${serverUrl}/api/Excel/Open`}
        saveUrl={`${serverUrl}/api/Excel/Save`}
        allowSave={true}
        selectionSettings={{ mode: "Multiple" }}
        created={onCreated}
        beforeSave={beforeSave}
        openComplete={onOpenComplete}
        actionBegin={actionBegin}
        allowFreezePane={true}
        actionComplete={onActionComplete}
        showAggregate={false}
        fileMenuItemSelect={(e) => 
        handleFileMenuItemClick(e, inputOpenFileRef)}
        contextMenuItemSelect={(e) => {
          selectedEvent = e.item.id;
          onContextMenuItemSelect(e, contextMenuArguments);
        }}
        cellEditing={(arg)=>onCellEditing(arg, spreadsheetRef.current, propDataType)}
        cellEdit={(arg)=>onCellEdit(arg, editDialog)}
        //fileMenuBeforeOpen={(arg)=>onFileMenuBeforeOpen(arg, spreadsheetRef?.current)}
        contextMenuBeforeOpen={onContextMenuBeforeOpen}
        beforeSort={onBeforeSort}
        sortComplete={onSortComplete}
      ></SpreadsheetComponent>
    </div>
  );
};
export default Spreadsheet;
