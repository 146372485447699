import ExecuteAPI from './ExecuteAPI';
// import { CompareStrings } from '...../utils/Util'

var request: RequestInit = {
    method: "GET",
  };
//Adds existing properties of the class to the existingpropIds array   
//Adds properties to the reorderColumnHeaders array
//Finds all the objects of the lookup types  

  export const GetOwnerId = (propId: any, propValue: any): any => {
    console.log('GetOwnerId called');
    let ownerId;
    const url = window.Host + "/structure/properties/" + propId;
    ExecuteAPI(url, request).then((response) => {
      response?.json().then((data) => {
        const url2 = window.Host + "valuelists/" + data.ValueList + "/items";
        ExecuteAPI(url2, request).then((response1) => {
          response1!.json().then((data1) => {
            ownerId = data1.Items.find((x) => x.Name == propValue)?.ID;
            return ownerId;
            //console.log(ownerId);
          });
        });
      });
    });
  }

  export async function GetVaults(token: any): Promise<any> {
    let url = window.Host + "/session/vaults?auth=" + token;
    let response = await fetch(url, request);
    if (response === undefined) return;
    let data = await response?.json();
    return data;
  }

  export async function GetToken(authObject: any): Promise<any> {
    const url = window.Host + "/server/authenticationtokens";    
    const request: RequestInit = {
      method: "POST",
      body: JSON.stringify(authObject),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    let response = await fetch(url, request);
    let data = await response?.json();
    return data;
  }

  export async function GetServerStatus(): Promise<any> {
    let url = window.Host + "/server/status";
    let response = await fetch(url, request);
    if (response === undefined) return;
    let data = await response?.json();
    return data;
  }

  export async function GetObjectTypes(): Promise<any> {
    let url = window.Host + "/structure/objecttypes?type=real";
    let response = await ExecuteAPI(url, request);
    //console.log(response);
    if (response === undefined || response.status == 403) return;
    let data = await response?.json();
    //console.log(data);
    return data?.sort(function (a, b) {
      return compareStrings(a.Name, b.Name);
    });
  }

  export async function GetObjectType(objTypeId: any): Promise<any> {
    let url = window.Host + "/structure/objecttypes/" + objTypeId;
    let response = await ExecuteAPI(url, request);
    if (response === undefined) return;
    let data = await response?.json();
    return data;
  }

  export async function GetClasses(objTypeId: any): Promise<any> {
    let url = window.Host + "/structure/classes?objtype=" + objTypeId;
    let response = await ExecuteAPI(url, request);
    if (response === undefined || response.status == 403) return;
    let data = await response.json();
    
    return data?.sort(function (a, b) {
        return compareStrings(a.Name, b.Name);
    });
  }

  export async function GetClassProperties(clsId: any): Promise<any> {
    let url = window.Host + "/structure/classes/" + clsId;
    let response = await ExecuteAPI(url, request);
    if (response === undefined) return;
    let data = await response.json();
    return data;
  }

  export async function GetProperty(objTypeId: any, objId: any, propId: any): Promise<any> {
    let url = window.Host + "objects/" + objTypeId + "/" + objId + "/latest/properties/" + propId + "?forDisplay=true";
    let response = await ExecuteAPI(url, request);
    if (response === undefined) return;
    let data = await response.json();
    return data;
  }

  export async function GetLatestProperties(objTypeId: any, objId: any): Promise<any> {
    let url = window.Host + "objects/" + objTypeId + "/" + objId + "/latest/properties?forDisplay=true";
    let response = await ExecuteAPI(url, request);
    if (response.status == 404) return;
    if (response === undefined) return;
    let data = await response.json();
    return data;
  }

  export async function GetObject(objTypeId: any, clsId: any, propId: any, propValue: any): Promise<any> {
    console.log('GetObject called');
    let url = window.Host + "objects/" + objTypeId + "?p100=" + clsId + "&p" + propId + "=" + propValue;
    let response = await ExecuteAPI(url, request);
    if (response === undefined) return;
    let data = await response.json();
    return data;
  }

  export async function GetVLObjects(objTypeId: any): Promise<any> {
    let url = window.Host + "valuelists/" + objTypeId + "/items";
    let response = await ExecuteAPI(url, request);
    if (response === undefined) return;
    let data = await response.json();
    return data;
  }

  export async function GetObjects(objTypeId: any): Promise<any> {
    let url = window.Host + "objects/" + objTypeId ;
    let response = await ExecuteAPI(url, request);
    if (response === undefined) return;
    let data = await response.json();
    return data;
  }

  export async function GetClassObjects(objTypeId: any, clsId: any): Promise<any> {
    let url = window.Host + "objects/" + objTypeId + "?p100=" + clsId;
    let response = await ExecuteAPI(url, request);
    if (response === undefined) return;
    let data = await response.json();
    return data;
  }

  export async function GetObjectVersion(objTypeId: any, objId: any): Promise<any> {
    let url = window.Host + "objects/" + objTypeId + "/" + objId;
    let response = await ExecuteAPI(url, request);
    if (response === undefined) return;
    let data = await response.json();
    return data;
  }

  export async function GetPropertyDetails(propDef: any): Promise<any> {
    let url = window.Host + "/structure/properties/" + propDef;
    let response = await ExecuteAPI(url, request);
    if (response === undefined) return;
    let prop = await response.json();
    return prop;
  }

  export async function CheckOutObject(docId: any): Promise<any> {
    var data = {
      value: 2,
    };
  
    var url = window.Host + "objects/0/" + docId + "/latest/checkedout";
    var request: RequestInit = {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    let response = await ExecuteAPI(url, request);
    if (response === undefined) return;
    let data1 = await response!.json();
    return data1;
  }

  export async function UpdateViewReport(docId: any, objId: any, fileId: any, file: any): Promise<any> {
    var data2 = {
      value: 0, //check-in
    };
    var request = {
      method: "PUT",
      body: file,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    var url = window.Host + "objects/0/" + objId + "/files/" + fileId + "/content";    
    let response = await ExecuteAPI(url, request);

    //check in the object
    var request1 = {
      method: "PUT",
      body: JSON.stringify(data2),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    var url1 = window.Host + "objects/0/" + docId + "/latest/checkedout";
    response = await ExecuteAPI(url1, request1);
    let data1 = await response!.json();
    return data1;
  }

  export async function GetFileContent(objTypeId: any, docId: any, fileId: any): Promise<any> {
    var request: RequestInit = {
      method: "GET",
    };
    let url = window.Host + "/objects/" + objTypeId + "/" + docId + "/latest/files/" + fileId + "/content";
    let response = await ExecuteAPI(url, request);
    return response;
  }

  export async function CreateValueList(objTypeId: any, text: string) {
    let url = window.Host + "valuelists/" + objTypeId + "/items"
    var newProp = {
      'Name': text
    };
    let request: RequestInit = {
      method: "POST",
      body: JSON.stringify(newProp)
    };

    let response = await ExecuteAPI(url, request);
    let data = await response?.json();
  }

  export async function CreateObject(objTypeId: any, text: string) {
    let url = `${window.Host}objects/${objTypeId}`;
    let request: RequestInit = {
        method: "POST",
        body: text
    };

    let response = await ExecuteAPI(url, request);
    let data = await response?.json();
    return data;    
  }

  export async function UpdateObject(objTypeId: any, objId: any,  text: string) {
    let url = `${window.Host}objects/${objTypeId}/${objId}/latest/properties`;
    let request: RequestInit = {
        method: "POST",
        body: JSON.stringify(text)
    };

    let response = await ExecuteAPI(url, request);
    let data = await response?.json();
    return data;    
  }

  function compareStrings(a, b) {
    a = a?.toLowerCase();
    b = b?.toLowerCase();
    if (a === undefined || a === "") {
      a = "";
    }
    if (b === undefined || b === "") {
      b = "";
    }
    return a < b ? -1 : a > b ? 1 : 0;
  }


