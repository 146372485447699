import {
  AccountInfo,
  AuthenticationResult,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { registerLicense } from "@syncfusion/ej2-base";
import NavigationBar from "./components/NavigationBar";
import RoutesPage from "./routes/RoutesPage";
import spreadsheetApi from "./services/apis/spreadsheetApi/spreadsheetApi";
import "./styles/App.scss";
import { constantVar } from "./utils/constant";
import useConfig from "./utils/useConfig";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

// Registering Syncfusion license key
registerLicense(constantVar?.syncfusionKey);

const App = () => {
  const [config] = useConfig();
  if (!config) return <>Loading config...</>;

  const msalInstance = new PublicClientApplication(config ?? config);
  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }
  msalInstance.enableAccountStorageEvents();
  msalInstance.addEventCallback((event) => {
    if (
      (event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS) &&
      event.payload
    ) {
      let result = event.payload as AccountInfo;
      msalInstance.setActiveAccount(result);
      sessionStorage.setItem("isAuthenticated", "true");
      let res = event.payload as AuthenticationResult;
      const userPayload = {
        homeAccountId: res?.account?.homeAccountId,
        environment: res?.account?.environment,
        tenantId: res?.account?.tenantId,
        username: res?.account?.username,
        localAccountId: res?.account?.localAccountId,
        name: res?.account?.name,
        authorityType: res?.account?.authorityType,
        clientId: sessionStorage.getItem("apiClientId"),
      };
      spreadsheetApi.saveUserProfile(userPayload);
      sessionStorage.setItem("loggedInUser", res?.account!.username!);
      localStorage.setItem("viewMavenUser", res?.account!.username!);
      // localStorage.setItem("AADToken", res.accessToken);
    }
  });

  return (
    <MsalProvider instance={msalInstance}>
      <div className="App" id="target">
        <NavigationBar />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <RoutesPage />
      </div>
    </MsalProvider>
  );
};

export default App;
