import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { Route, Routes } from "react-router-dom";
import Login from "../pages/Login";
import Spreadsheet from "../pages/Spreadsheet";
import ServerConnection from "../pages/ServerConnection";

const RoutesPage = () => {
  return (
    <>
      <UnauthenticatedTemplate>
        <Routes>
          <Route path="/" element={<Login />} />
        </Routes>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Routes>
          {/* <Route path="/" element={<Home />} /> */}
          <Route path="/" element={<Spreadsheet />} />
          <Route path="/serverconnection" element={<ServerConnection />} />
        </Routes>
      </AuthenticatedTemplate>
    </>
  );
};

export default RoutesPage;
