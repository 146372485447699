import { getCellAddress, getColumnHeaderText } from "@syncfusion/ej2-spreadsheet";
import { useEffect, useState } from "react";
import { AdminColumnStyle, AutomaticColumnStyle, ColumnHeaderStyle, ColumnStyle, RequiredColumnStyle } from "../styles/SettingAndStyles";

export function getLastEmptyRow(spreadsheet: any){
  const sheet = spreadsheet.getActiveSheet();
  const rowCount = sheet.usedRange.rowIndex;
  
  let lastEmptyRow = rowCount + 1; // Start with the next row after the last used row
  for (let i = rowCount; i >= 0; i--) {
    const row = sheet.rows[i];
    const isEmpty = row?.cells?.every(cell => !cell.value);
    if (isEmpty) {
      lastEmptyRow = i;
    } else {
      break; // Exit loop when a non-empty row is found
    }
  }
  return lastEmptyRow;
}

export function addAdminColumnHeaders(spreadsheet: any){
  let usedColIdx = spreadsheet?.getActiveSheet().usedRange?.colIndex!;
  window.RequiredAdminColumns?.forEach((col, index) => {
    spreadsheet?.updateCell(
      {
        value: col,
        style: ColumnHeaderStyle
      },
      getCellAddress(0, usedColIdx + index + 1)
    );
    // added in arrange columns values
    window.isAdminColumnsAddedForTrans = true
  });
}


export async function updateCell(spreadsheet: any, rowIndex: any, colIndex: any, value: any) {
  if (colIndex < 0) return;
  return spreadsheet?.updateCell({
      value,
      style: ColumnStyle,
  }, getCellAddress(rowIndex, colIndex));
}

export async function adjustColumnWidths(spreadsheet: any) {
  const usedColIdx = spreadsheet?.getActiveSheet().usedRange?.colIndex || 0;
  const colHeaderText = getColumnHeaderText(usedColIdx + 1);
  const columnRange = `${getColumnHeaderText(1)}:${colHeaderText}`;
  spreadsheet?.setColumnsWidth(120, [columnRange]);
 // spreadsheet?.enableToolbarItems("View", [8], true);
}

export function getSheetIndex(spreadsheet: any, sheetName: any) {
  const sheet = spreadsheet?.sheets.find(x => x.name === sheetName);
  return sheet?.id ? sheet.id - 1 : undefined;
}

export async function updateSheetCells( spreadsheet: any, objTypeId: any, objTypeName: any, clsId: any, clsName: any, lastEmptyRow: any, usedColIdx: any ) {
  if (lastEmptyRow < 1) lastEmptyRow = 1; //just add one row for recording the ObjectType, ObjectClass
  for (let i = 1; i <= lastEmptyRow; i++) {
      await updateCell(spreadsheet, i, usedColIdx + 3, objTypeId);
      await updateCell(spreadsheet, i, usedColIdx + 4, objTypeName);
      await updateCell(spreadsheet, i, usedColIdx + 5, clsId);
      await updateCell(spreadsheet, i, usedColIdx + 6, clsName);
      await updateCell(spreadsheet, i, usedColIdx + 7, "FALSE");
      await updateCell(spreadsheet, i, usedColIdx + 8, "FALSE");
      await updateCell(spreadsheet, i, usedColIdx + 9, "");
  }
}

export async function updateExistingObject(spreadsheet: any, rowIndex: any, item: any) {
  await updateCell(spreadsheet, rowIndex, window.ColumnHeaders?.indexOf("GUID"), item.ObjectGUID);
  await updateCell(spreadsheet, rowIndex, window.ColumnHeaders?.indexOf("ID"), item.ObjVer.ID);
  await updateCell(spreadsheet, rowIndex, window.ColumnHeaders?.indexOf("Modified"), "True");
}

export async function updateNewObject(spreadsheet: any, rowIndex: any) {
  await updateCell(spreadsheet, rowIndex, window.ColumnHeaders?.indexOf("ID"), "-1");
}

export function hideAdminColumns(spreadsheet: any) {
  window.ReorderColumnHeaders?.forEach((element) => {
    console.log('Hiding Admin Columns');
    if (element.IsAdmin === true && window.ColumnHeaders?.indexOf(element.Title) !== -1) {
      let index = window.ColumnHeaders?.indexOf(element.Title);
      spreadsheet?.hideColumn(index, index, true);
      element.Hidden= true;
    }      
  });
}

export function markObligatoryProperties(isNewRow: boolean = false, isRearrange: boolean = false, spreadsheet: any) {
 
  let sheetName = spreadsheet?.getActiveSheet().name;
  let usedRowIdx = spreadsheet?.getActiveSheet().usedRange?.rowIndex!;
  if (isNewRow || isRearrange) {
    usedRowIdx = usedRowIdx + 1;
  }
  window.ExistingPropIds?.forEach((prop) => {
    var index = window.ColumnHeaders?.indexOf(prop.Name);
    if (prop.Required && index !== -1) {
      let colHeaderText = getColumnHeaderText(index + 1);
      spreadsheet?.cellFormat(
        RequiredColumnStyle, 
        sheetName + "!" + colHeaderText + 2 + ":" + colHeaderText + usedRowIdx
      );
    }
    if (prop.AutomaticValueType != 0 && index !== -1) {
      let colHeaderText = getColumnHeaderText(index + 1);
      spreadsheet?.cellFormat(
        AutomaticColumnStyle,
        sheetName + "!" + colHeaderText + 2 + ":" + colHeaderText + usedRowIdx );
    }
  });
 // console.log(styleData)
  // window.ReorderColumnHeaders?.forEach((element) => {
  //   var index = window.ColumnHeaders?.indexOf(element.Title);
  //   if (element.IsAdmin == true && index !== -1) {
  //     let colHeaderText = getColumnHeaderText(index + 1);
  //     console.log(element.Title)
  //     console.log("Admin Column");
  //     console.log(colHeaderText)
  //     spreadsheet?.cellFormat(
  //       AdminColumnStyle,
  //       sheetName + "!" + colHeaderText + 2 + ":" + colHeaderText + usedRowIdx
  //     );
  //   }
  // });

}
