import { useEffect, useState } from "react";

export function compareStrings(a: any, b: any){
    a = a?.toLowerCase();
    b = b?.toLowerCase();
    if (a === undefined || a === "") {
      a = "";
    }
    if (b === undefined || b === "") {
      b = "";
    }
    return a < b ? -1 : a > b ? 1 : 0;
  };
  
  export function compareStringsDesc(a: any, b: any) {
    a = a?.toLowerCase();
    b = b?.toLowerCase();
    if (a === undefined || a === "") {
      a = "";
    }
    if (b === undefined || b === "") {
      b = "";
    }
    return a < b ? 1 : a > b ? -1 : 0;
  }
  
  export function compareNumbers(a: any, b: any) {
    return a - b;
  }
  
  export function compareNumbersDesc(a: any, b: any) {
    return b - a;
  }
  
  export function compareDates(a: any, b: any) {
    return a - b;
  }
  
  export function compareDatesDesc(a: any, b: any) {
    return b - a;
  }
  
 export const letterToNumber = (args: any) => {
    var base = "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
      i,
      j,
      result = 0;
    for (i = 0, j = args?.length - 1; i < args?.length; i += 1, j -= 1) {
      result += Math.pow(base.length, j) * (base.indexOf(args[i]) + 1);
    }
    return result;
  };