import React, { useEffect, useRef } from 'react';
import { GetOwnerId, GetClassProperties, GetPropertyDetails, GetObjectType, GetClasses, GetProperty, 
  GetObject, GetObjects, GetClassObjects, GetVLObjects } from './MFilesService';
import { getColumnHeaderText } from '@syncfusion/ej2-react-spreadsheet';
// import { CompareStrings } from '...../utils/Util'


interface Property {
  Name: string;
  ID: string;
  Required: boolean;
  AutomaticValueType: number;
  DataType: number;
  DependencyPD: number;
}

var request: RequestInit = {
    method: "GET",
  };
//Adds existing properties of the class to the existingpropIds array   
//Adds properties to the reorderColumnHeaders array
//Finds all the objects of the lookup types  
export const GetProperties = async (sheetName: string, clsId: any) => {
  if (clsId === undefined) return;
  await IsLookupIdDefined(clsId);
  let data = await GetClassProperties(clsId);
  if (data === undefined) return;
  // Process AssociatedPropertyDefs
  await Promise.all(
    data?.AssociatedPropertyDefs?.map(async (element: any) => {
      let prop = await GetPropertyDetails(element.PropertyDef);
      if (window.ExistingPropIds?.find((x) => x.Id == prop.ID && x.SheetName == sheetName) == null && !prop.Predefined) {
        window.ExistingPropIds?.push({
          Name: prop.Name,
          Id: prop.ID,
          Required: element.Required,
          AutomaticValueType: prop.AutomaticValueType,
          DataType: prop.DataType,
          DepPD: prop.DependencyPD,
          SheetName: sheetName
        });
      }

      let propId = prop.ID;
      let propDataType = prop.DataType;
      let ownerId = -1;

      if (propDataType === 9 || propDataType === 10) {
        // if (prop.DepPD > 0) {
        //   let ownerPD = window.ExistingPropIds.find((x) => x.Id == prop.DepPD)?.Name;
        //   let ownerPDCol = window.ColumnHeaders.indexOf(ownerPD);
        //   if (ownerPDCol !== -1) {
        //     let ownerPDValue = getCell(
        //       currentRow,
        //       ownerPDCol,
        //       spreadsheet!.getActiveSheet()
        //     ).value;
        //     GetOwnerId(prop.DepPD, ownerPDValue);
        //   }
        // }
        GetDataList(prop.Name, propId, propDataType, ownerId, prop.AutomaticValueType, true, 0);
      }

      if (
        window.ReorderColumnHeaders?.find(
          (x) => x.Title === prop.Name && x.SheetName === sheetName
        ) == null
      ) {
        if (window.ColumnHeaders?.indexOf(prop.Name) !== -1) {
          let text = getColumnHeaderText(window.ColumnHeaders?.length + 1);
          window.ReorderColumnHeaders?.push({
            Title: prop.Name,
            IsAdmin: false,
            Visible: true,
            Hidden: false,
            SheetName: sheetName,
            PropertyLabel: '-',
            Key: text.replace(/[0-9]/g, '')
          });
        } else {
          window.ReorderColumnHeaders?.push({
            Title: prop.Name,
            IsAdmin: false,
            Visible: false,
            Hidden: false,
            SheetName: sheetName,
            PropertyLabel: '-',
            Key: ''
          });
        }
      }
    })
  );
};

export const IsLookupIdDefined = async(clsId: any) => {
    if (clsId === undefined) return;
    if (clsId === "-1") return;
    await GetClassProperties(clsId).then(async (data) => {
      if (data === undefined) return;
      if (data?.AssociatedPropertyDefs === undefined) return;
      if (data) {
        for (var element of data?.AssociatedPropertyDefs!) {
           await GetPropertyDetails(element.PropertyDef).then((prop) => {
              if (prop?.Name === "Lookup ID") {
                if (window.LookupIds?.find((x) => x.clsId == clsId) === undefined) {
                  window.LookupIds?.push({ clsId: clsId, lkupId: prop?.ID });
                }
                //break;
              }
            });
        }
      }
    });
  }
  
 
  const GetDataList = (
    propName: string,
    propId: number,
    propDataType: number,
    ownerId: any,
    isAutomatic: boolean,
    loadAll: boolean, 
    clsId: any = 0
  ) => {
    var isMultipleClass = false;
    var objTypeId;
    GetPropertyDetails(propId).then((data) => {
      objTypeId = data.ValueList;
      if (data.HasStaticFilter === true) {
        data?.StaticFilters?.forEach((item: any) => {
          if (item.Expression.DataPropertyValuePropertyDef === 100) {
            if (
              item.TypedValue.DataType === 9 &&
              item.TypedValue.Lookup !== undefined
            ) {
              isMultipleClass = false;
              clsId = item.TypedValue.Lookup.Item;
            } else if (item.TypedValue.DataType === 10) {
              if (item.TypedValue.Lookups !== undefined) {
                clsId = "";
                isMultipleClass = true;
                item?.TypedValue?.Lookups?.forEach((lkup: any) => {
                  clsId += lkup.Item + ",";
                });
                clsId = clsId.slice(0, -1);
              } else if (item.TypedValue.Lookup !== undefined) {
                  isMultipleClass = false;
                clsId = item.TypedValue.Lookup.Item;
              }
            }
          }
        });
      }
      var tmpclsId;
      if (isMultipleClass && clsId !== undefined && !(Number.isInteger(clsId))) {
        var temp = clsId!.split(",");
        tmpclsId = temp[0];
      } else tmpclsId = clsId;
      if (tmpclsId !== undefined && tmpclsId !== -1)
        IsLookupIdDefined(Number(tmpclsId));
      //to find if its a class object or valuelist
      GetObjType(
        propName,
        propId,
        clsId,
        data,
        propDataType,
        ownerId,
        loadAll,
        isMultipleClass,
        isAutomatic
      );
    });
  }

  const GetObjType = (
    propName: string,
    propId: number,
    clsId: any,
    data: any,
    propDataType: number,
    ownerId: any,
    loadAll: boolean,
    isMultipleClass: boolean,
    isAutomatic: boolean
  ) => {
    var objTypeId;
    GetObjectType(data.ValueList).then((data1) => {
      if (data1?.RealObjectType === true) {
        objTypeId = data.ValueList;
        GetClassId(
          propName,
          propId,
          clsId,
          data.ValueList,
          propDataType,
          ownerId,
          loadAll,
          isMultipleClass,
          isAutomatic
        );
      } else {
        //console.log("Its a valuelist");
        objTypeId = data.ValueList;
        clsId = -1;
        GetData(
          propName,
          propId,
          clsId,
          objTypeId,
          propDataType,
          ownerId,
          loadAll,
          isMultipleClass,
          false,
          isAutomatic
        );
      }
    });
  };

  const GetClassId = (
    propName: string,
    propId: number,
    clsId: any,
    objTypeId: any,
    propDataType: number,
    ownerId: any,
    loadAll: boolean,
    IsMultipleClass: boolean,
    isAutomatic: boolean
  ) => {
    GetClasses(objTypeId).then((classes) => {
        if (classes.length > 1) {
        } else {
          clsId = classes[0].ID;
          if (clsId !== undefined) IsLookupIdDefined(clsId);
        }
        GetData(
          propName,
          propId,
          clsId,
          objTypeId,
          propDataType,
          ownerId,
          loadAll,
          IsMultipleClass, false,
          isAutomatic

        );
    });
  };

  export async function GetData(
    propName: string,
    propId: number,
    clsId: any,
    objTypeId: any,
    propDataType: number,
    ownerId: any,
    loadAll: boolean,
    IsMultipleClass: boolean, IsRefresh: boolean,
    isAutomatic: boolean
  ) {
    var url2, list, haslkup, tmpclsId, dropdownData, multiSelectData, data;
    if (clsId == -1) {
      //valuelist
      tmpclsId = clsId;
      await GetVLObjects(objTypeId).then((obj) => {
        obj?.Items?.sort(function (a, b) {
          return compareStrings(a.Title, b.Title);
        });
        data = obj;
      });
      if (propDataType == 9) {
        list = { text: "Name", value: "ID" };
      }
      if (propDataType == 10) {
        list = { text: "Name", value: "Name" };
      }
    } else {
      if (clsId == 0)//list but class not defined
      {
        await GetObjects(objTypeId).then((obj) => {
          obj?.Items?.sort(function (a, b) {
            return compareStrings(a.Title, b.Title);
          });
          data = obj;
        });
        url2 = window.Host + "objects/" + objTypeId;
      }
      else {
        await GetClassObjects(objTypeId, clsId).then((obj) => {
          obj?.Items?.sort(function (a, b) {
            return compareStrings(a.Title, b.Title);
          });
          data = obj;
        });
        url2 = window.Host + "objects/" + objTypeId + "?p100=" + clsId;
      }
      if (propDataType == 9) {
        list = { text: "Title", value: "ObjVer.ID" };
      }
      if (propDataType == 10) {
        list = { text: "Title", value: "Title" };
      }
      //find out whether the class has Lookup ID property
      //get the property id
      //console.log(IsMultipleClass);
      if (IsMultipleClass && !Number.isInteger(clsId)) {
        var temp = clsId?.split(",");
        tmpclsId = temp[0];
      } else tmpclsId = clsId;
      var lkupId = window.LookupIds?.find((x) => x.clsId === Number(tmpclsId))?.lkupId;
      if (lkupId == undefined) haslkup = false;
      else haslkup = true;
    }
   
    if (clsId !== -1 && lkupId !== undefined) {
      data?.Items?.forEach((element: any) => {
        GetProperty(objTypeId, element.ObjVer.ID, lkupId).then((data1) => {
            if (data1?.Status !== 404) {
              element["LookupId"] = data1?.TypedValue?.DisplayValue;
            }
          });
      });
      if (window.SingleSelectProperties?.find((x) => x.Id == propId)) {
        window.SingleSelectProperties.find((x) => x.Id == propId).DataArray =
          data.Items;
      }
      if (window.MultiSelectProperties?.find((x) => x.Id == propId)) {
        window.MultiSelectProperties.find((x) => x.Id == propId).DataArray =
          data.Items;
      }
    }
    if (propDataType === 9) {
      if (ownerId != -1) {
        dropdownData = data.Items.filter((x) => x.OwnerID == ownerId);
      } else {
        dropdownData = data.Items;
      }
      if (window.SingleSelectProperties?.find((x) => x.Id == propId)) {
        window.SingleSelectProperties.find((x) => x.Id == propId).DataArray =
          dropdownData;
      } else {
        window.SingleSelectProperties?.push({
          Id: propId,
          Name: propName,
          ClsId: tmpclsId,
          ObjTypeId: objTypeId,
          DataArray: dropdownData,
          HasLookup: haslkup,
          IsMultipleClass: IsMultipleClass,
          IsAutomatic: isAutomatic
        });
      }
    //   if (!loadAll) {
    //     dropDownListObject.fields = list;
    //     dropDownListObject.dataSource = dropdownData;
    //     dialogInstance.header = propName + " List";
    //     if (!isAutomatic)
    //       dialogInstance.show();
    //   }
    //   if (IsRefresh) {
    //     dropDownListObject.fields = list;
    //     dropDownListObject.dataSource = dropdownData;
    //     dropDownListObject.refresh();
    //   }
    } else if (propDataType === 10) {
      if (window.MultiSelectProperties?.find((x) => x.Id == propId)) {
        window.MultiSelectProperties.find((x) => x.Id == propId).DataArray =
          data.Items;
      } else {
        window.MultiSelectProperties?.push({
          Id: propId,
          Name: propName,
          ClsId: clsId,
          ObjTypeId: objTypeId,
          DataArray: data.Items,
          HasLookup: haslkup,
          IsMultipleClass: IsMultipleClass,
          IsAutomatic: isAutomatic
        });
      }
    //   if (!loadAll) {
    //     multiSelectData = data.Items;
    //     multiSelectListObject.fields = list;
    //     multiSelectListObject.dataSource = multiSelectData;
    //     dialogInstance1.header = propName + " List";
    //     if (!isAutomatic)
    //       dialogInstance1.show();
    //   }
    //   if (IsRefresh) {
    //     multiSelectListObject.fields = list;
    //     multiSelectListObject.dataSource = multiSelectData;
    //     multiSelectListObject.refresh();
    //   }
    }
  }

  // check whether the object already exists in the vault
  export async function CheckObjectExists(objTypeId: any, clsId: any, uniquePropertyId: any, uniquePropertyValue: any,  sheetName: any): Promise<any> {
    if (window.ReorderColumnHeaders?.find((x) => x.Title === "Lookup ID" && x.SheetName === sheetName) === undefined) {
        return await GetObject(objTypeId, clsId, uniquePropertyId, uniquePropertyValue);
    } else {
        if (window.LookupIds?.length > 0) {
            var lkupIdPropDefID = window.LookupIds[0]?.lkupId; //fetch lookup Id and see whether the objects exists already
            return await GetObject(objTypeId, clsId, lkupIdPropDefID, uniquePropertyValue);
        }
    }
  }

  function compareStrings(a, b) {
    a = a?.toLowerCase();
    b = b?.toLowerCase();
    if (a === undefined || a === "") {
      a = "";
    }
    if (b === undefined || b === "") {
      b = "";
    }
    return a < b ? -1 : a > b ? 1 : 0;
  }


