const ExecuteAPI = async (url: string, request: RequestInit) => {
    var response, token, authType, vaultId;
    try {
      token = localStorage.getItem("token")!;
      authType = localStorage.getItem("authType");
      if (sessionStorage.getItem("vaultId") != null) {
        vaultId = sessionStorage.getItem("vaultId")!.toString();
      }
      if (authType === "MFiles") {
        if (url.includes("?")) {
          url = url + "&auth=" + token;
        } else {
          url = url + "?auth=" + token;
        }
        request.headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
        };
        response = await fetch(url, request);
      } else {
        //console.log(token);
        sessionStorage.setItem("isAuthenticated", "true");
        request.headers! = {
          Authorization: "Bearer " + token,
          "X-Vault": vaultId!,
          Accept: "application/json",
          "Content-Type": "application/json",
        };
        response = await fetch(url, request);
      }
      return response;
    } catch (error) {
      console.log("Error : " + error);
      return response;
    }
};

export default ExecuteAPI;